import * as React from "react"
import * as styles from './header.module.styl'
import { graphql } from "gatsby"
import { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { widthInfo } from "../../util/index.js"
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import PropTypes from "prop-types"

const width = widthInfo()
const links = [
  {
    text: 'Home',
    link: '/',
    langLink: '/urdu',
    targetLangLink: '/urdu/',
    index: 0,
  },
  {
    text: 'About Barwaqt',
    link: '/aboutUs',
    target: '/aboutUs/',
    langLink: '/urdu/aboutUs',
    targetLangLink: '/urdu/aboutUs/',
    index: 1,
  },
  {
    text: 'Loan Introduction',
    link: '/loanIntroduction',
    target: '/loanIntroduction/',
    langLink: '/urdu/loanIntroduction',
    targetLangLink: '/urdu/loanIntroduction/',
    index: 2,
  },
  {
    text: 'Services',
    isOpened: false,
    index: 3,
    children: [
      {
        text: 'FAQs',
        link: '/faq',
        target: '/faq/',
        langLink: '/urdu/faq',
        targetLangLink: '/urdu/faq/',
        index: '3-1',
      },
      {
        text: 'Account Deletion',
        link: '/accountDeletion',
        target: '/accountDeletion/',
        langLink: '/urdu/accountDeletion',
        targetLangLink: '/urdu/accountDeletion/',
        index: '3-2',
      },
    ],
  },
  {
    text: 'Complaints',
    link: '/complaints',
    target: '/complaints/',
    langLink: '/urdu/complaints',
    targetLangLink: '/urdu/complaints/',
    index:4,
  },
]

const Header = ({ siteTitle, pathname }) => {
  const [linkList] = useState(links)
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false)
  const handleMouseEnter = () => {
    setIsSubmenuVisible(true)
  }
  const handleMouseLeave = () => {
    setIsSubmenuVisible(false)
  }
  const [on, setOn] = React.useState(false)
  const lightSwitch = () => setOn(on => !on)
  const { languages, t, i18n, changeLanguage } = useI18next()
  const curLang = i18n.language
  const [selects, setSelects] = useState(languages.map(l => {
    return {
      name: l === 'urdu' ? 'اردو' : 'English',
      lang: l
    }
  }))
  const [lang, setLang] = useState(curLang)
  const isBrowser = typeof document !== "undefined"
  let rootEl = null
  if (isBrowser) {
    rootEl = document.documentElement
  }
  const [selected, setSelected] = useState(curLang)

  const handleChange = event => {
    const lang = event.target.value
    setSelected(lang)
    setLang(lang)
    changeLanguage(lang)
  }

  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(-1)
  function handleSubMenuItemClick(index) {
    setActiveSubMenuIndex(activeSubMenuIndex === index ? -1 : index)
  }
  const subMenuStates = linkList.map((_, index) => index === activeSubMenuIndex)

  const dismissMenuClick = event => {
    const target = event.target
    const currentTarget = event.currentTarget
    if (target === currentTarget) {
      setOn(false)
    }
  }

  useEffect(() => {
    rootEl.lang = lang
    if (lang === 'en') {
      rootEl.setAttribute('dir', 'ltr')
    } else if (lang === 'urdu') {
      rootEl.setAttribute('dir', 'rtl')
    }
    let activeSubMenuIndex = -1
    linkList.some((_, index) => {
      if (!_.children) {
        if (_.link === pathname || _.langLink === pathname) {
          activeSubMenuIndex = index
          return
        }
      } else {
        return _.children.some(item => {
          if (item.link === pathname || item.langLink === pathname) {
            activeSubMenuIndex = index
            return
          }
        })
      }
    })
    setActiveSubMenuIndex(activeSubMenuIndex)
  }, [lang, pathname, linkList])

  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <StaticImage
          className={styles.headerLogo}
          src="../../images/header/header-logo@2x.png"
          layout="constrained"
          alt="A corgi smiling happily"
          placeholder="blurred"
        />
        {width > 1024 ?
          <div>
            <ul className={styles.headerList}>
              {linkList.map((item) => {
                if (item.children && item.children.length) {
                  return (
                    <li
                    role="presentation"
                    key={item.index}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className={`${styles.headerSubItem} ${item.children.some(item => pathname === item.target || pathname === item.link || pathname === item.langLink || pathname === item.targetLangLink) ? 'header-active' : ''}`}
                    >
                      <a>{t(`${item.text}`)}</a>
                      {item.children && isSubmenuVisible && (
                        <ul className={styles.subMenus}>
                          {
                            item.children.map(sub =>(
                              <li
                              role="presentation"
                              key={sub.index}
                              className={`${styles.subMenuItem} ${pathname === sub.target || pathname === sub.link || pathname === sub.langLink || pathname === sub.targetLangLink ? 'subMenuItem-active' : ''
                                }`}
                              >
                                <Link to={`${sub.link}`}>{t(`${sub.text}`)}</Link>
                              </li>
                            ))
                          }
                        </ul>
                      )}
                    </li>
                  )
                } else {
                  return (
                    <li
                    role="presentation"
                    key={item.index}
                    className={`${styles.headerItem} ${pathname === item.target || pathname === item.link || pathname === item.langLink || pathname === item.targetLangLink ? 'header-active' : ''
                      }`}
                    >
                      <Link to={`${item.link}`}>{t(`${item.text}`)}</Link>
                    </li>
                  )
                }
              })}
              <li className={styles.languageSwitcherSelect}>
                <select value={selected} onChange={handleChange}>
                {selects.map((lng) => (
                  <option value={lng.lang} key={lng.lang}>
                    {lng.name}
                  </option>
                ))}
                </select>
              </li>
              <li className={`${styles.headerItem} ${styles.headerItemSeedcred}`}>
                <a aria-label="header download" className={`${styles.headerItem} ${styles.headerDownload}`} target="_blank" href="https://www.seedcredfintec.com/">
                  <StaticImage
                    className={styles.headerDownloadIcon}
                    src="../../images/header/logo-seedcred@2x.png"
                    layout="constrained"
                    alt="A corgi smiling happily"
                    placeholder="blurred"
                  />
                </a>
              </li>
            </ul>
          </div>
          :
          <section className={styles.languageSwitcher}>
            <span className={styles.languageSwitcherSelectMb}>
              <select value={selected} onChange={handleChange}>
              {selects.map((lng) => (
                <option value={lng.lang} key={lng.lang}>
                  {lng.name}
                </option>
              ))}
              </select>
            </span>
            {/* onKeyPress={() => lightSwitch()} */}
            <div onClick={() => lightSwitch()} role="button"
            tabIndex="0">
              <StaticImage
                className={styles.headerMuneIcon}
                layout="constrained"
                src="../../images/header/menuBlack.png"
                alt="A corgi smiling happily"
                placeholder="blurred"
              />
            </div>
          </section>
        }
      </div>
      {/* onKeyPress={() => lightSwitch()} */}
      <div
        className={`${styles.menu} ${on ? 'block' : 'none'}`}
        onClick={(e) => dismissMenuClick(e)}
        role="button"
        tabIndex="0">
        <ul className={styles.menuList}>
          <div className={styles.trangle}></div>
          {
            links.map((item, index) => {
              if (item.children && item.children.length) {
                return (
                  <li key={index} className={`${styles.subMenusItem} ${subMenuStates[index] ? styles.isOpened : ''}`}>
                    <div className={styles.subMenusEntry} onClick={() => handleSubMenuItemClick(index)}>
                      <span className={styles.menuListItem}>{t(`${item.text}`)}</span>
                      <span className={styles.menuListItemArrow}></span>
                    </div>
                    {item.children && subMenuStates[index] && (
                      <ul className={styles.subMenus}>
                        {item.children.map(sub => (
                          <li
                            key={sub.index}
                            className={`${pathname === sub.target || pathname === sub.link || pathname === sub.langLink || pathname === sub.targetLangLink ? styles.isActive : ''}`}
                          >
                            <Link to={`${sub.link}`}>{t(`${sub.text}`)}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )
              } else {
                return (
                  <li onClick={() => lightSwitch()} key={index} className={styles.menuItem}>
                    <Link to={item.link} key={index}>
                      <span className={styles.menuListItem}>{t(`${item.text}`)}</span>
                    </Link>
                  </li>
                )
              }
            })
          }
        </ul>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["header"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
