import * as React from "react"
import * as styles from "./footer.module.styl"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { widthInfo } from "../../util/index.js"
import { Link } from 'gatsby'

let width = widthInfo()
const Footer = ({ siteTitle }) => {
  const updateDate = '12/26/2023'
  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        {
          width > 1024 ? (
            <>
              <section className={styles.footerInnerTop}>
                <div className={styles.mediaMenus}>
                  <div className={styles.logo}>
                    <StaticImage
                      src="../../images/logo.svg"
                      alt="barwaqt logo"
                      layout="constrained"
                      placeholder="blurred"
                    />
                    <h2>Barwaqt</h2>
                  </div>
                  <div className={styles.statementDesc}>
                    <p>Product of SEEDCRED FINANCIAL</p>
                    <p>SERVICES LIMITED</p>
                  </div>
                  <div className={styles.mediaLogoList}>
                    <a href="https://www.facebook.com/BarwaqtbySCFSL" target="_blank">
                      <StaticImage
                        src="../../images/facebook.svg"
                        alt="facebook logo"
                        layout="constrained"
                        placeholder="blurred"
                      />
                    </a>
                    {/* <a>
                      <StaticImage
                        className={styles.mediaLogoItem}
                        src="../../images/linkedin.svg"
                        alt="linkedin logo"
                        layout="constrained"
                        placeholder="blurred"
                      />
                    </a> */}
                    <a href="https://www.instagram.com/barwaqtbyscfsl" target="_blank">
                      <StaticImage
                        src="../../images/instagram.svg"
                        alt="instagram logo"
                        layout="constrained"
                        placeholder="blurred"
                      />
                    </a>
                    {/* <a>
                      <StaticImage
                        className={styles.mediaLogoItem}
                        src="../../images/twitter.svg"
                        alt="twitter logo"
                        layout="constrained"
                        placeholder="blurred"
                      />
                    </a>
                    <a>
                      <StaticImage
                        className={styles.mediaLogoItem}
                        src="../../images/youtube.svg"
                        alt="youtube logo"
                        layout="constrained"
                        placeholder="blurred"
                      />
                    </a> */}
                  </div>
                </div>
                <div className={styles.company}>
                  <h2 className={styles.companyText}>Company</h2>
                  <ul className={styles.oUl}>
                    <li className={styles.oLi}>
                      <Link to="/aboutUs">About Barwaqt</Link>
                      {/* <a href="/aboutUs">About Barwaqt</a> */}
                    </li>
                    <li className={styles.oLi}>
                      <Link to="/loanIntroduction">Loan Introduction</Link>
                      {/* <a href="/loanIntroduction">Loan Introduction</a> */}
                    </li>
                  </ul>
                </div>
                <div className={styles.services}>
                  <h2 className={styles.servicesText}>Services</h2>
                  <ul className={styles.oUl}>
                    <li className={styles.oLi}>
                      <a href="https://h5.barwaqtfintec.com/html/TermsConditions.docx.html" target="_blank">Terms & Conditions</a>
                    </li>
                    <li className={styles.oLi}>
                      <a href="https://h5.barwaqtfintec.com/html/PrivacyPolicy.docx.html" target="_blank">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
                <div className={styles.seedcredLogo}>
                  <StaticImage
                    src="../../images/seedcred.png"
                    alt="seedcred logo"
                    layout="constrained"
                    placeholder="blurred"
                  />
                  <div className={styles.seedcredLogoDesc}>
                    <p>SEEDCRED</p>
                    <p>FINANCIAL SERVICES LIMITED</p>
                  </div>
                </div>
              </section>
              <section className={styles.footerInnerBottom}>
                <p>License: NO.SC/NBFC-1/208/SFSL/2021-05</p>
                <p>Last updated: {updateDate}</p>
              </section>
            </>
          ) : (
            <div className={styles.mFooterInner}>
              <section className={styles.companyLogos}>
                <ul>
                  <li>
                    <StaticImage
                      src="../../images/seedcred.png"
                      alt="seedcred logo"
                      layout="constrained"
                      placeholder="blurred"
                    />
                    <div className={styles.seedcredLogoDesc}>
                      <p>SEEDCRED</p>
                      <p>FINANCIAL SERVICES LIMITED</p>
                    </div>
                  </li>
                  <li>
                    <StaticImage
                      src="../../images/logo.svg"
                      alt="barwaqt logo"
                      layout="constrained"
                      placeholder="blurred"
                    />
                    <div className={styles.logoDesc}>
                      <p>Barwaqt</p>
                      <p>Product of SEEDCRED FINANCIAL SERVICES LIMITED</p>
                    </div>
                  </li>
                </ul>
              </section>
              <section className={styles.siteMap}>
                <div className={styles.mapItem}>
                  <h2 className={styles.mapItemTitle}>Company</h2>
                  <ul>
                    <li>
                      <a href="/aboutUs">About Barwaqt</a>
                    </li>
                    <li>
                      <a href="/loanIntroduction">Loan Introduction</a>
                    </li>
                  </ul>
                </div>
                <div className={styles.mapItem}>
                  <h2 className={styles.mapItemTitle}>Services</h2>
                  <ul>
                    <li>
                      <a href="https://h5.barwaqtfintec.com/html/TermsConditions.docx.html" target="_blank">Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="https://h5.barwaqtfintec.com/html/PrivacyPolicy.docx.html" target="_blank">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </section>
              <section className={styles.mMediaLogoList}>
                <a href="https://www.facebook.com/BarwaqtbySCFSL" target="_blank">
                  <StaticImage
                    src="../../images/facebook.svg"
                    alt="facebook logo"
                    layout="constrained"
                    placeholder="blurred"
                  />
                </a>
                <a href="https://www.instagram.com/barwaqtbyscfsl" target="_blank">
                  <StaticImage
                    src="../../images/instagram.svg"
                    alt="instagram logo"
                    layout="constrained"
                    placeholder="blurred"
                  />
                </a>
              </section>
              <section className={styles.sideInfo}>
                <p>License: NO.SC/NBFC-1/208/SFSL/2021-05</p>
                <p>Last updated: {updateDate}</p>
              </section>
            </div>
          )
        }
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
