/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import Footer from "./footer/footer"
import "./layout.css"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query siteTitleQueryAndSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  return (
    <section>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} pathname={pathname} />
      <div>
        <main>{children}</main>
      </div>
      {
        (pathname !== '/repaymentViabyEA' && pathname !== '/repaymentViabyWa') ?
          <Footer siteTitle={data.site.siteMetadata?.title || `Title`} /> : null
      }
    </section>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
