import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'

// export function encrypt(data) {
//   data = JSON.stringify(data)
//   let secretKey = '8n)8I*yH@%!5%ZD0'
//   secretKey = CryptoJS.enc.Utf8.parse(secretKey)
//   // secretKey = CryptoJS.SHA256(secretKey)
//   console.log(secretKey, 'secretKey')
//   const cipher = CryptoJS.AES.encrypt(data, secretKey, {
//     mode: CryptoJS.mode.ECB,
//     padding: CryptoJS.pad.Pkcs7,
//   })
//   const base64Cipher = cipher.ciphertext.toString(CryptoJS.enc.Base64)
//   return base64Cipher
// }

export function encrypt(generalText) {
  const text = typeof generalText === 'object'
      ? JSON.stringify(generalText)
      : generalText
  const utf8Text = CryptoJS.enc.Utf8.parse(text)
  const originalKey = 'cf48ada35861537bd2257f909c79eafc'
  const originalIv = 'b81c91e7c4055a663c203e72a86db4be'
  const key = CryptoJS.enc.Hex.parse(originalKey)
  const iv = CryptoJS.enc.Hex.parse(originalIv)
  const encrypted = CryptoJS.AES.encrypt(utf8Text, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encodeURIComponent(encrypted.toString())
}

export function deEncrypt(encrypted) {
  let secretKey = 'cf48ada35861537bd2257f909c79eafc'
  secretKey = CryptoJS.enc.Utf8.parse(secretKey)
  let iv = 'b81c91e7c4055a663c203e72a86db4be'
  iv = CryptoJS.enc.Utf8.parse(iv)
  const decipher = CryptoJS.AES.decrypt(encrypted, secretKey, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  const resultDecipher = CryptoJS.enc.Utf8.stringify(decipher)
  return resultDecipher
}

export function genIdWithPhoneNumAndDateTime(phoneNum) {
  const { $y, $M, $D, $H, $m, $s, $ms } = dayjs()
  return `${phoneNum}${$y}${$M + 1}${$D}${$H}${$m}${$s}${$ms}`
}

const isBrowser = () => typeof window !== "undefined"
export const widthInfo = function () {
  if (isBrowser()) {
    return window.document.body.clientWidth
  }
}

export function digitalThousandth(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
