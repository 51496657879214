// extracted by mini-css-extract-plugin
export var company = "footer-module--company--CBEal";
export var companyLogos = "footer-module--companyLogos--ygiKo";
export var companyText = "footer-module--companyText--7KhjS";
export var footer = "footer-module--footer--wy9MY";
export var footerInner = "footer-module--footerInner--UkbuI";
export var footerInnerBottom = "footer-module--footerInnerBottom--xFof7";
export var footerInnerTop = "footer-module--footerInnerTop--lfEu7";
export var logo = "footer-module--logo--0p5jl";
export var logoDesc = "footer-module--logoDesc--lq0Mu";
export var mFooterInner = "footer-module--mFooterInner--uolmH";
export var mMediaLogoList = "footer-module--mMediaLogoList--Wf9pQ";
export var mapItem = "footer-module--mapItem--A60Ji";
export var mapItemTitle = "footer-module--mapItemTitle--3mYcm";
export var mediaLogoItem = "footer-module--mediaLogoItem--UyuYx";
export var mediaLogoList = "footer-module--mediaLogoList--2fnvf";
export var mediaMenus = "footer-module--mediaMenus--ojSQp";
export var oLi = "footer-module--oLi--0rcsR";
export var oUl = "footer-module--oUl--WeeRD";
export var seedcredLogo = "footer-module--seedcredLogo--BlWs3";
export var seedcredLogoDesc = "footer-module--seedcredLogoDesc--j+rCF";
export var services = "footer-module--services--DLVTV";
export var servicesText = "footer-module--servicesText--BLS5L";
export var sideInfo = "footer-module--sideInfo--jLIfW";
export var siteMap = "footer-module--siteMap--yY8Ys";
export var statementDesc = "footer-module--statementDesc--Fyuj-";